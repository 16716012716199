import * as React from 'react'
import { useEffect, useState } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import InformerOrgChartSearchableDropDown from 'src/components/InformerOrgChart/InformerOrgChartSearchableDropDown'
import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useOrgChartFlowStore from 'src/lib/stores/orgChartFlowStores'

const InformerOrgChartSidePanel = ({
  chartRawData,
  structureData,
  setStructureData,
  updateLayoutToDatabase,
  deleteSeat,
  deleteSeatAllocation,
  isAllowedToEdit,
  structureView,
}) => {
  const availableUsers = useOrgChartFlowStore((state) => state.availableUsers) // Available staff, those who have yet to be allocated, which will appear at the top of the list
  const allocatedUsers = useOrgChartFlowStore((state) => state.allocatedUsers) // Users that have already been allocated to a seat, which will appear at the bottom of the list
  const deleteSeatAllocationLoading = useOrgChartFlowStore(
    (state) => state.deleteSeatAllocationLoading,
  )
  const setEditNode = useOrgChartFlowStore((state) => state.setEditNode) // Function to set the state of editNodeId
  const editNode = useOrgChartFlowStore((state) => state.editNode) // State of if the user is editing a node
  const deleteSeatLoading = useOrgChartFlowStore(
    (state) => state.deleteSeatLoading,
  ) // Loading state for when deleting a seat

  const [positionText, setPositionText] = useState(null) // State for the seat position text box
  const [responsibilities, setResponsibilities] = useState([]) // State for the seat position text box
  const [measurables, setMeasurables] = useState([]) // State for the seat position text box
  const [descriptionText, setDescriptionText] = useState('') // State for the seat description text box
  const [selectedUser, setSelectedUser] = useState(null) // State for the selected user in the select box
  const [availUsers, setAvailUsers] = useState([]) // State for the selected user in the select box
  const [allocUsers, setAllocUsers] = useState([]) // State for the selected user in the select box
  const [drawOpen, setDrawOpen] = useState(false) // State for if the draw is open or not
  const [newResponsibility, setNewResponsibility] = React.useState('') // State for the new responsibility text box
  const [newMeasurable, setNewMeasurable] = React.useState('') // State for the new responsibility text box
  const [entityAbn, setEntityAbn] = useState('')
  const confirmDeleteSeat = useConfirm()

  // Function to handle the deletion of a responsibility
  const handleDeleteResponsibility = (index) => {
    const newResponsibilityList = [...responsibilities]
    newResponsibilityList.splice(index, 1)
    setResponsibilities(newResponsibilityList)
  }

  // Function to handle the deletion of a measurable
  const handleDeleteMeasurable = (index) => {
    const newMeasurableList = [...measurables]
    newMeasurableList.splice(index, 1)
    setMeasurables(newMeasurableList)
  }

  // Function to handle the addition of a responsibility
  const handleAddResponsibility = () => {
    if (newResponsibility.trim()) {
      setResponsibilities([...responsibilities, newResponsibility.trim()])
      setNewResponsibility('')
    }
  }

  // Function to handle the addition of a measurable
  const handleAddMeasurable = () => {
    if (newMeasurable.trim()) {
      setMeasurables([...measurables, newMeasurable.trim()])
      setNewMeasurable('')
    }
  }

  // Function to update the seat information
  const updateSeatInformation = async () => {
    // Only update position if it's an entity. Update the rest if it's a seat

    const person =
      selectedUser && editNode.type === 'seat'
        ? chartRawData.find((person) => person.id === selectedUser)
        : null
    const updatedData = structureData.map((seat) => {
      if (seat.id !== editNode.id) return seat
      return {
        ...seat,
        data: {
          ...seat.data,
          person: person ? person : seat.data?.person,
          description: editNode.type === 'seat' ? descriptionText : entityAbn,
          responsibilities: JSON.stringify(responsibilities),
          measurables: JSON.stringify(measurables),
          position: positionText,
        },
      }
    })
    setStructureData(updatedData)
    if (isAllowedToEdit && structureView) {
      await updateLayoutToDatabase(updatedData)
    }
    setEditNode(null)
  }

  useEffect(() => {
    setNewMeasurable('')
    setNewResponsibility('')
    if (editNode) {
      setDrawOpen(true)
      // Grab the position
      setPositionText(editNode.position)
      // Grab the job description if it's a seat
      if (editNode.type === 'seat') {
        setDescriptionText(editNode.description)
        setResponsibilities(
          editNode.responsibilities
            ? JSON.parse(editNode.responsibilities)
            : [],
        )
        setMeasurables(
          editNode.measurables ? JSON.parse(editNode.measurables) : [],
        )

        // Grab the person
        setSelectedUser(editNode.person ? editNode.person.id : '')
        // Set the available and allocated users
        setAvailUsers(
          editNode?.person
            ? [
                {
                  value: editNode.person.id,
                  label: editNode.person.name,
                },
                ...availableUsers,
              ]
            : [...availableUsers],
        )
        setAllocUsers(
          allocatedUsers.filter((user) => user.value !== editNode?.person?.id),
        )
      } else {
        // If it's an entity, just set these to null
        setSelectedUser(null)
        setDescriptionText(null)
        setEntityAbn(editNode.description)
        setMeasurables([])
        setResponsibilities([])
      }
    } else {
      // If there is no edit node, close the draw
      setDrawOpen(false)
    }
  }, [editNode])

  return (
    <Drawer anchor="right" open={drawOpen}>
      <Stack
        spacing={4}
        style={{ width: '20vw', padding: '16px' }}
        direction="column"
        flexGrow={1}
      >
        <Stack direction={'row'} alignItems="center">
          <Typography variant="h6">
            Edit {editNode?.type === 'seat' ? 'Seat' : 'Entity'}
          </Typography>
          <div style={{ position: 'absolute', top: '16px', right: '16px' }}>
            <Tooltip title={'Close'}>
              <div>
                <Button
                  fullWidth={false}
                  variant="outlined"
                  loading={deleteSeatLoading}
                  className="min-w-[0] rounded-full p-2"
                  onClick={() => {
                    setEditNode(null)
                  }}
                >
                  <XMarkIcon className="h-5 w-5" />
                </Button>
              </div>
            </Tooltip>
          </div>
        </Stack>
        <TextField
          value={positionText}
          label={editNode?.type === 'seat' ? 'Seat Position' : 'Entity Name'}
          size="small"
          InputLabelProps={{ style: { fontSize: 12 } }}
          onChange={(e) => {
            setPositionText(e.target.value)
          }}
        ></TextField>

        {editNode?.type === 'ent' && (
          <TextField
            value={entityAbn}
            label={'ABN'}
            size="small"
            InputLabelProps={{ style: { fontSize: 12 } }}
            onChange={(e) => {
              setEntityAbn(e.target.value)
            }}
          ></TextField>
        )}
        {editNode?.type === 'seat' && (
          <>
            <Stack direction={'row'} alignItems="center" spacing={1}>
              <InformerOrgChartSearchableDropDown
                availUsers={availUsers}
                allocUsers={allocUsers}
                initialValue={selectedUser}
                setSelectedUser={setSelectedUser}
              />

              {/* // If there is a person allocated to the seat, show the delete
                    button to delete the person from the seat */}
              {editNode?.person && (
                <Tooltip title={'Remove from this seat'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="outlined"
                      loading={deleteSeatAllocationLoading}
                      className="min-w-[0] rounded-full p-1"
                      onClick={() => {
                        deleteSeatAllocation()
                        setSelectedUser('')
                      }}
                    >
                      <TrashIcon className="h-3 w-3 text-red-600" />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Stack>
            <TextField
              label={'Job Description'}
              value={descriptionText}
              InputProps={{ style: { fontSize: 12 } }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              multiline
              rows={7}
              onChange={(e) => {
                setDescriptionText(e.target.value)
              }}
            ></TextField>
            <Stack flexGrow={1} spacing={1} overflow="auto">
              <Typography
                variant="body1"
                style={{ fontSize: 12, fontWeight: 'bold', margin: '8px 0' }}
              >
                Responsibilities
              </Typography>
              <List component="nav">
                {responsibilities.map((responsibility, index) => (
                  <ListItem
                    key={index}
                    divider
                    style={{ height: '30px', padding: '8px 0px' }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          className="line-clamp-1 overflow-hidden text-ellipsis text-xs"
                        >
                          {responsibility}
                        </Typography>
                      }
                    />
                    <Tooltip title={'Delete this responsibility'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="outlined"
                          loading={deleteSeatAllocationLoading}
                          className="min-w-[0] rounded-full p-1"
                          onClick={() => handleDeleteResponsibility(index)}
                        >
                          <TrashIcon className="h-3 w-3 text-red-600" />
                        </Button>
                      </div>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>

              <Stack direction={'row'} spacing={1} alignItems="center">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  size="small"
                  label="New Responsibility"
                  value={newResponsibility}
                  onChange={(e) => setNewResponsibility(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddResponsibility()
                      e.preventDefault() // Prevent the default action to avoid form submission in case this is part of a form
                    }
                  }}
                />
                <Tooltip title={'Add this responsibility'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="outlined"
                      loading={deleteSeatAllocationLoading}
                      className="min-w-[0] rounded-full p-1"
                      onClick={() => handleAddResponsibility()}
                    >
                      <PlusIcon className="h-3 w-3" />
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
              <Typography
                variant="body1"
                style={{ fontSize: 12, fontWeight: 'bold', marginTop: '30px' }}
              >
                Measurables
              </Typography>
              <List component="nav">
                {measurables.map((measurable, index) => (
                  <ListItem
                    key={index}
                    divider
                    style={{ height: '30px', padding: '8px 0px' }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          className="line-clamp-1 overflow-hidden text-ellipsis text-xs"
                        >
                          {measurable}
                        </Typography>
                      }
                    />
                    <Tooltip title={'Delete this measurable'}>
                      <div>
                        <Button
                          fullWidth={false}
                          variant="outlined"
                          loading={deleteSeatAllocationLoading}
                          className="min-w-[0] rounded-full p-1"
                          onClick={() => handleDeleteMeasurable(index)}
                        >
                          <TrashIcon className="h-3 w-3 text-red-600" />
                        </Button>
                      </div>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>

              <Stack direction={'row'} spacing={1} alignItems="center">
                <TextField
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: 12 } }}
                  size="small"
                  label="New Measurable"
                  value={newMeasurable}
                  onChange={(e) => setNewMeasurable(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAddMeasurable()
                      e.preventDefault() // Prevent the default action to avoid form submission in case this is part of a form
                    }
                  }}
                />
                <Tooltip title={'Add this measurable'}>
                  <div>
                    <Button
                      fullWidth={false}
                      variant="outlined"
                      loading={deleteSeatAllocationLoading}
                      className="min-w-[0] rounded-full p-1"
                      onClick={() => handleAddMeasurable()}
                    >
                      <PlusIcon className="h-3 w-3" />
                    </Button>
                  </div>
                </Tooltip>
              </Stack>
            </Stack>
          </>
        )}
        <Stack
          direction="row"
          spacing={1}
          style={{ marginTop: 'auto', marginBottom: '16px' }}
        >
          <Button
            onClick={() => {
              confirmDeleteSeat({
                title: 'Delete seat?',
                description: 'Are you sure you want to delete this seat?',
              }).then((isConfirmed) => {
                if (!isConfirmed) return
                deleteSeat()
                setEditNode(null)
              })
            }}
            className="border-0 bg-red-500 text-white hover:border-0 hover:bg-red-700"
          >
            Delete Seat
          </Button>
          <Button onClick={updateSeatInformation}>Save</Button>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default InformerOrgChartSidePanel
